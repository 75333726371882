import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";

// import _debounce from "lodash.debounce"
import { debounce } from "lodash";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import "../css/index.scss";

// import House from "../components/House"
// import Header from "../components/Header"
// import SEO from "../components/seo"

// import HouseVideo from "../video/yasnaya-house.mp4"
import HouseVideo from "../video/yasnaya-house.mp4";
import dot from "../images/dot.svg";

// import bottleNoLogo from "../images/bottle-nologo.png"
import bottle1 from "../images/bottles/bottle3.jpg";
import bottle2 from "../images/bottles/bottle2.jpg";
import bottle3 from "../images/bottles/bottle1.jpg";
import bottle4 from "../images/bottles/bottle5.jpg";
import bottle5 from "../images/bottles/bottle4.jpg";

import iconCircle from "../images/icon-circle.svg";
import logo1 from "../images/logo-afisha-daily.png";
import logo2 from "../images/logo-the-village.png";
import logo3 from "../images/logo-the-moscow-times.png";

import insta1 from "../images/instagram/insta1.png";
import insta2 from "../images/instagram/insta2.png";
import insta3 from "../images/instagram/insta3.png";
import insta4 from "../images/instagram/insta4.png";
import insta5 from "../images/instagram/insta5.png";
import insta6 from "../images/instagram/insta6.png";

import bottle360 from "../images/bottle-360.png";
import bottle360static from "../images/bottle-360-static.jpg";
import bottleUp from "../images/bottle-up.jpg";
import bottleBottom from "../images/bottle-bottom.jpg";

// import bottle 360 images
function importAll(r) {
  return r.keys().map(r);
}
const currentFrm = importAll(
  require.context("../images/bottle360", false, /\.(png|jpe?g|svg)$/)
);

if (typeof window === "undefined") {
  global.window = {};
}

if (typeof window !== "undefined") {
  // console.log("--defined")
  gsap.registerPlugin(ScrollTrigger);
}

const IndexPage = () => {
  const [width, setWidth] = useState(window.outerWidth);
  const [loaded, setLoaded] = useState(false);
  const imageBottle3 = useRef();
  const handleLoadImage = () => setLoaded(true);

  const { t } = useTranslation();
  const videoHouseRef = useRef(null);

  // var bottle3TrueWidth
  // var bottle3ProcentWidth

  let HOUSECONTAINER;
  let HOUSEBG;
  let HOUSEMASK;
  let HOUSEBLUR;
  let HOUSEVIDEO;
  let DOTWRAPPER;
  let HOUSETEXT;
  let HOUSETEXTDELAY;
  let HOUSEGRADIENT;
  let HOUSEBOTTLES;
  let HOUSEBOTTLE1;
  let HOUSEBOTTLE2;
  let HOUSEBOTTLE3;
  let HOUSEBOTTLE4;
  let HOUSEBOTTLE5;
  let BOTTLESCONTAINER;
  let HOUSESLOGAN;
  let BOTTLE360CONTAINER;
  let BOTTLE360CANVAS;
  let BOTTLEUP;

  useEffect(() => {
    // console.log("---start useEffect")

    // window.onresize = doALoadOfStuff
    // function doALoadOfStuff() {
    //   console.log("-----------------------------------------resize")
    // }

    // const handleResize = debounce(() => setWidth(window.outerWidth), 100)
    // window.addEventListener("resize", handleResize)
    // return () => {}

    HOUSECONTAINER = document.querySelector(".house-container");
    HOUSEBG = document.querySelector(".house-bg");
    HOUSEMASK = document.querySelector(".house-mask");
    HOUSEBLUR = document.querySelector(".house-blur");
    HOUSEVIDEO = document.querySelector(".house-video");
    DOTWRAPPER = document.querySelector(".dot-wrapper");
    HOUSETEXT = document.querySelector(".house-text");
    HOUSEGRADIENT = document.querySelector(".house-gradient");

    // HOUSEBOTTLES = document.querySelector(".house-bottles")
    // HOUSEBOTTLE = document.querySelector(".house-bottle")
    HOUSEBOTTLE1 = document.querySelector(".house-bottle1");
    HOUSEBOTTLE2 = document.querySelector(".house-bottle2");
    HOUSEBOTTLE3 = document.querySelector(".house-bottle3");
    HOUSEBOTTLE4 = document.querySelector(".house-bottle4");
    HOUSEBOTTLE5 = document.querySelector(".house-bottle5");

    BOTTLESCONTAINER = document.querySelector(".bottles-wrapper");
    HOUSESLOGAN = document.querySelector(".house-slogan");
    BOTTLE360CONTAINER = document.querySelector(".bottle360-container");
    BOTTLE360CANVAS = document.querySelector(".bottle360-canvas");
    BOTTLEUP = document.querySelector(".bottle-up");

    function getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
      var oRatio = width / height,
        cRatio = cWidth / cHeight;
      return function () {
        if (contains ? oRatio > cRatio : oRatio < cRatio) {
          this.width = cWidth;
          this.height = cWidth / oRatio;
        } else {
          this.width = cHeight * oRatio;
          this.height = cHeight;
        }
        // this.left = (cWidth - this.width) * (pos / 100)
        // this.right = this.width + this.left
        return this;
      }.call({});
    }

    function getImgSizeInfo(img) {
      var pos = window
        .getComputedStyle(img)
        .getPropertyValue("object-position")
        .split(" ");
      return getRenderedSize(
        true,
        img.width,
        img.height,
        img.naturalWidth,
        img.naturalHeight,
        parseInt(pos[0])
      );
    }

    // window.dispatchEvent(new Event("resize"))

    // document.querySelector("#bottle3").addEventListener("load", function (e) {
    // bottle3TrueWidth = getImgSizeInfo(document.getElementById("bottle3")).width
    // bottle3TrueWidth = document.getElementById("bottle3").offsetWidth
    // bottle3ProcentWidth = (bottle3TrueWidth * 100) / window.outerWidth

    console.log("---loaded: ", loaded);

    if (imageBottle3.current.complete) setLoaded(true);

    // loaded ? scenesLoad() : console.log("--not yet loaded--")

    scenesLoad();

    function scenesLoad() {
      sceneHouse();
      sceneBottle();
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    }
  });

  //////////////////////////////////////////////////////// house
  function sceneHouse() {
    // console.log("-inside sceneHouse()- ")

    // if (isNaN(bottle3TrueWidth)) {
    //   console.log("---isNun---")
    //   bottle3TrueWidth = document.getElementById("bottle3").clientWidth
    // }

    // bottle3ProcentWidth = (bottle3TrueWidth * 100) / window.outerWidth

    let MASKSCALE;

    let windowRatio = window.outerHeight / window.outerWidth;
    // console.log("-----------windowRatio: ", windowRatio)

    if (windowRatio < 1.5) {
      MASKSCALE = 20 * windowRatio * 0.5 + "vw";
    } else {
      MASKSCALE = "20vw";
    }

    // console.log("-------MASKSCALE: ", MASKSCALE)

    let MASKINITIALSCALE = "50%";
    let MASKSIZEONE = "1000%";
    let MASKSCALEDURATION = 1;
    let HOUSETEXTDELAY = 0.8;
    let BOTTLESHIFT = "-50%";
    let BOTTLESSCALE = 0.6;
    let BOTTLESINITIALSCALE = 120;
    // let MASKSCALE = 8.5
    // let MASKSCALE = bottle3ProcentWidth / 1.8 + "vw"
    // console.log("6---MASKSCALE: % ", MASKSCALE)

    let TEXTMOVE = "-50%";
    let SLOGANMOVE = "-10%";
    // let SLOGANDELAY = 1.6
    let BOTTLESCONTAINERDURATION = 1.25;
    let BOTTLESSCALEDELAY = 0;

    // if (document.documentElement.clientWidth > 767) {
    if (window.matchMedia("screen and (min-width: 768px)").matches) {
      if (windowRatio < 1.5) {
        MASKSCALE = 20 * windowRatio * 0.5 + "vw";
      } else {
        MASKSCALE = "20vw";
      }

      MASKSCALEDURATION = 1.2;
      HOUSETEXTDELAY = 0.8;
      MASKINITIALSCALE = "25%";
      // BOTTLESSCALE = 1.25
      // BOTTLESSCALE = 1.4
      // BOTTLESSCALE = 0.85
      // BOTTLESSCALE = 0.4
      // BOTTLESINITIALSCALE = 80

      // MASKSCALE = bottle3ProcentWidth / 1.8 + "vw"
      // console.log("---MASKSCALE: ", MASKSCALE)

      SLOGANMOVE = "-50%";
      HOUSETEXTDELAY = 1;
      // BOTTLESSCALEDELAY = 0

      // SLOGANDELAY = 1.8
      BOTTLESCONTAINERDURATION = 1.2;
    }
    if (window.matchMedia("screen and (min-width: 1024px)").matches) {
      // MASKSIZEONE = "500%"
      TEXTMOVE = "-100%";
      SLOGANMOVE = "-20%";
      // SLOGANDELAY = 1.8
    }
    if (window.matchMedia("screen and (min-width: 1280px)").matches) {
      SLOGANMOVE = "-50%";

      if (windowRatio < 1) {
        BOTTLESHIFT = "-25%";
      } else {
        //  MASKSCALE = "20vw"
      }
    }
    if (document.documentElement.clientWidth > 1439) {
      // SLOGANDELAY = 1.8
    }

    if (
      document.documentElement.clientWidth /
        document.documentElement.clientHeight >
      2
    ) {
      SLOGANMOVE = "-10%";
    }

    var action = gsap
      .timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          trigger: HOUSECONTAINER,
          scrub: true,
          pin: true,
          pinSpacing: true,
          // markers: true,
          start: "top top",
          // start: "+=100",
          end: "+=10000",
          toggleClass: { targets: HOUSECONTAINER, className: "is-active" },
          // end: "+=1000",
        },
      })
      .to(DOTWRAPPER, { display: "none" }, 0)
      .to(DOTWRAPPER, { autoAlpha: -10, duration: 0.1 }, 0)
      .fromTo(
        HOUSEMASK,
        { webkitMaskSize: MASKINITIALSCALE, maskSize: MASKINITIALSCALE },
        {
          ease: "power1.in",
          webkitMaskSize: MASKSIZEONE,
          maskSize: MASKSIZEONE,
          duration: MASKSCALEDURATION,
        },
        0
      )
      .to(
        HOUSEVIDEO,
        {
          // ease: "power4.Out",
          // ease: "expo.Out",
          ease: "power1.out",
          scale: 1,
          duration: 0.4,
        },
        0
      )
      .to(
        HOUSEBLUR,
        {
          ease: "power2.in",
          filter: "blur(0px)",
          duration: 0.4,
        },
        0
      )

      .to(
        HOUSETEXT,
        {
          delay: HOUSETEXTDELAY,
          opacity: 1,
          duration: 0.1,
        },
        0
      )

      .to(
        HOUSETEXT,
        {
          // delay: 0.2,
          delay: HOUSETEXTDELAY + 0.1,
          // ease: "expo.in",
          y: TEXTMOVE,
          duration: 0.4,
        },
        0
      )

      .to(
        HOUSETEXT,
        {
          // delay: 1.4,
          delay: HOUSETEXTDELAY + 0.5,
          opacity: 0,
          duration: 0.2,
        },
        0
      )

      .to(
        HOUSEBG,
        {
          delay: HOUSETEXTDELAY - 0.6,
          // ease: "expo.out",
          ease: "power4.out",
          webkitMaskSize: MASKSCALE,
          maskSize: MASKSCALE,
          duration: 2,
        },
        1
      )

      .to(
        HOUSEGRADIENT,
        // { webkitMaskSize: "1000%", maskSize: "1000%" },
        {
          delay: HOUSETEXTDELAY - 0.6,
          ease: "power4.out",
          webkitMaskSize: MASKSCALE,
          maskSize: MASKSCALE,
          opacity: 1,
          duration: 2,
          // backgroundImage:
          //   "linear-gradient(90deg, #9B664A 0%, #9C5F40 15.62%, #924A1B 27.6%, #94532B 71.35%, #8B4D29 100%)",
          //   background: linear-gradient(90deg, #9B664A 0%, #9C5F40 15.62%, #924A1B 27.6%, #94532B 71.35%, #8B4D29 100%);
          // "linear-gradient(0deg, rgba(182, 126, 80, 0.7), rgba(182, 126, 80, 0.7))",
        },
        1
      )

      .to(
        HOUSEBLUR,
        {
          delay: HOUSETEXTDELAY - 0.2,

          // ease: "power1.in",
          ease: "power4.out",
          filter: "blur(5px)",
          duration: 1,
        },
        1
      )

      //

      .set(
        HOUSEVIDEO,
        {
          delay: HOUSETEXTDELAY + 0.6,
          // ease: "power4.Out",
          // ease: "expo.Out",
          // ease: "cine",
          opacity: 0,
          filter: "blur(0px)",

          // scale: 1,
          // duration: 1,
        },
        1
      )

      .set(
        HOUSEBG,
        {
          delay: HOUSETEXTDELAY + 0.8,
          autoAlpha: 0,
        },
        1
      )

      .set(
        // HOUSEBOTTLE3,
        ".house-bottle",
        {
          opacity: 1,
          delay: HOUSETEXTDELAY + BOTTLESSCALEDELAY - 0.1,
          force3D: true,
        },
        1
      )

      //

      // .fromTo(
      //   HOUSEBOTTLE3,
      //   // ".house-bottle",
      //   { scale: BOTTLESINITIALSCALE },
      //   {
      //     // delay: HOUSETEXTDELAY - 0.6,
      //     scale: BOTTLESSCALE,

      //     // x: "-50%",
      //     // duration: 2,
      //     // ease: "power2.out",
      //     // force3D: true,
      //   },
      //   0
      // )

      .to(
        HOUSEBOTTLE1,
        {
          delay: HOUSETEXTDELAY - 0.6,
          scale: BOTTLESSCALE,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE1,
        {
          delay: HOUSETEXTDELAY - 0.6,
          left: "-50%",
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE2,
        {
          delay: HOUSETEXTDELAY - 0.6,
          scale: BOTTLESSCALE,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE2,
        {
          delay: HOUSETEXTDELAY - 0.6,
          left: BOTTLESHIFT,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE3,
        {
          delay: HOUSETEXTDELAY - 0.6,
          scale: BOTTLESSCALE,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE4,
        {
          delay: HOUSETEXTDELAY - 0.6,
          scale: BOTTLESSCALE,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE4,
        {
          delay: HOUSETEXTDELAY - 0.6,
          right: BOTTLESHIFT,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE5,
        {
          delay: HOUSETEXTDELAY - 0.6,
          scale: BOTTLESSCALE,
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSEBOTTLE5,
        {
          delay: HOUSETEXTDELAY - 0.6,
          right: "-49%",
          duration: 2,
          // ease: "power2.out",
          ease: "power4.out",
          force3D: true,
        },
        1
      )

      .to(
        HOUSESLOGAN,
        {
          delay: HOUSETEXTDELAY + 0.7,
          opacity: 1,
          duration: 0.1,
        },
        1
      )
      .to(
        HOUSESLOGAN,
        {
          delay: HOUSETEXTDELAY + 0.7,
          ease: "power4.out",
          y: SLOGANMOVE,
          // y: "-50%",
          duration: 0.4,
        },
        1
      );

    // .set(
    //   ".house-bottle",
    //   {
    //     opacity: 1,
    //     delay: HOUSETEXTDELAY + 1.5,
    //   },
    //   1
    // )

    function startAction() {
      // console.log("8---startAction")
      // console.log("")
      //   gsap.to(HOUSEBG, {
      //     // ease: "power1.out",
      //     // filter: "blur(10px)",
      //     maskSize: "1000%",
      //     webkitMaskSize: "1000%",
      //     duration: 1800,
      //   })
    }

    // .to(
    //   HOUSEBLUR,
    //   {
    //     // delay: 1.5,

    //     ease: "power1.in",
    //     filter: "blur(0)",
    //     // duration: 0.5,
    //   },
    //   3
    // )
  }

  //////////////////////////////////////////////////////// bottle360
  function sceneBottle() {
    let BOTTLE360START = "center center-=5%";
    if (document.documentElement.clientWidth > 767) {
      BOTTLE360START = "center center-=25%";
    }

    const canvas = document.getElementById("bottle360");
    const context = canvas.getContext("2d");
    const parent = canvas.parentElement;

    // canvas.width = 1440
    // canvas.height = 1629

    canvas.width = 1440;
    canvas.height = 1628;

    var bottleContainer360 = document.getElementById("bottle360Wrapper");

    // canvas.width = bottleContainer360.offsetWidth
    // canvas.height = bottleContainer360.offsetHeight

    // console.log("---canvas.width: ", canvas.width)
    // console.log("---canvas.height: ", canvas.height)

    const frameCount = 101;

    const images = [];
    const bottleFr = {
      frame: 0,
    };

    for (let i = 0; i < frameCount; i++) {
      // debugger;
      const img = new Image();
      // img.src = currentFrame(i)
      img.src = currentFrm[i].default;
      images.push(img);
      console.log("---push img");
      // debugger
    }

    var actionBottle = gsap
      .timeline({
        // defaults: { duration: 1, ease: "none" },
        scrollTrigger: {
          // trigger: ".page-bottle-trigger",
          trigger: BOTTLE360CONTAINER,
          scrub: 1,
          pin: true,
          pinSpacing: true,
          // markers: true,
          start: BOTTLE360START,
          // end: "+=1000",
          onEnter: () => enter(),
          onLeave: () => leave(),
          onEnterBack: () => enterBack(),
          onLeaveBack: () => leaveBack(),
        },
        // onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
      })

      .to(
        BOTTLEUP,
        {
          // opacity: 0,
        },
        0
      )

      .to(
        bottleFr,
        {
          ease: "steps(" + frameCount + ")", // use a stepped ease for the sprite
          frame: frameCount - 1,
          snap: "frame",
          onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
        },
        0
      );

    // gsap.to(bottleFr, {
    //   frame: frameCount - 1,
    //   snap: "frame",
    //   scrollTrigger: {
    //     // scrub: 0.5,
    //     // pin: true,
    //   },
    //   onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
    // })

    images[0].onload = render;

    function render() {
      // })
      // console.log("---render", bottleFr.frame)
      // context.clearRect(0, 0, 388, 485)
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(images[bottleFr.frame], 0, 0);
    }

    function enter() {
      // console.log("---enter---")
      // document.getElementsByClassName("bottles-wrapper")[0].style.overflowX =
      //   "hidden"
    }

    function leave() {
      // console.log("---leave---")
    }

    function enterBack() {
      // console.log("---enterBack---")
    }

    function leaveBack() {
      // console.log("---leaveBack---")
      //   document.getElementsByClassName("bottles-wrapper")[0].style.overflowX =
      //     "visible"
    }
  }
  // })

  return (
    <>
      <Layout>
        <Helmet>
          <title>{t("header")}</title>
        </Helmet>
        {/* <Header fullWidth="true" bgGradient="true" /> */}
        {/* <Header /> */}
        {/* <House /> */}
        {/* <div className="footer-spacer">---</div>
        <div className="footer-spacer">---</div>
        <div className="footer-spacer">---</div>
        <div className="footer-spacer">---</div> */}
        <div className="test"></div>

        <main>
          {/* <div className="relative"> */}
          <div className="house-container">
            <div className="dot-wrapper">
              <div className="dot-direction">
                <img src={dot} alt=""></img>
              </div>
            </div>

            <div className="house-bg house-mask house-blur">
              <video
                ref={videoHouseRef}
                className="house-video"
                autoPlay
                playsInline
                muted
                loop
              >
                <source src={HouseVideo}></source>
              </video>
            </div>

            <div className="house-gradient gradient-mask"></div>

            {/* <img className="house-bottle house-bottle1" src={bottle1}></img> */}
            <div className="bottles-wrapper">
              <div className="house-bottles">
                {/* <img className="" src={bottle1}></img> */}
                {/* <img className="" src={bottle2}></img> */}
                <div className="house-bottle house-bottle1"></div>
                <div className="house-bottle house-bottle2"></div>

                <div
                  ref={imageBottle3}
                  id="bottle3"
                  className="house-bottle house-bottle3"
                ></div>
                {/* <img
                    id="bottle3"
                    className=""
                    src={bottle3}
                    ref={imageBottle3}
                    onLoad={handleLoadImage}
                    // ref={this.imageRef}
                  ></img> */}
                <div className="house-bottle house-bottle4"></div>
                <div className="house-bottle house-bottle5"></div>
                {/* <div className="house-bottle house-bottle4">
                  <img className="" src={bottle4}></img>
                </div>

                <div className="house-bottle house-bottle5">
                  <img className="" src={bottle5}></img>
                </div> */}
                {/* <img className="house-bottle house-bottle4" src={bottle4}></img>
                <img className="house-bottle house-bottle5" src={bottle5}></img> */}
              </div>
            </div>

            <div className="page house-text">
              <div className="house-text__gps">N54.073&deg;, E37.530&deg;</div>
              <div className="house-text__header">
                {t("welcome")}
                <br />
                {t("toYasnayaPolyana")}
                {/* в&nbsp;Ясную Поляну. */}
              </div>
            </div>

            <div className="page absolute house-slogan">
              <div className="home-slogan__sub">{t("tasteTitle")}</div>
              <div className="home-slogan__header">{t("tasteDesc")}</div>
            </div>
          </div>

          <div className="page">
            {/* <div className="house-slogan">
              <div className="home-slogan__sub">попробуйте</div>
              <div className="home-slogan__header">
                натуральные продукты из яблок, собранных в&nbsp;садах с
                историей.
              </div>
            </div> */}
            <div className="flex flex-wrap -mx-4 magazines">
              <div className="w-full px-4 w-100 md:w-1/3 pb-10 magazines__block">
                <div className="magazines__logo">
                  <img src={logo1} alt="logo1" />
                </div>
                <div className="magazines__text">
                  &laquo;Ясная Поляна для меня&nbsp;&mdash; не&nbsp;музей,
                  а&nbsp;место силы&raquo;: Илья Толстой рассказал Афише Дейли
                  о&nbsp;новой марке натуральных и&nbsp;вкусных продуктов.
                </div>

                <div className="magazines__link">
                  {/* <img
                      className="magazines__link-icon"
                      src={iconCircle}
                      alt=""
                    /> */}
                  <a
                    href="https://daily.afisha.ru/eating/17320-yasnaya-polyana-dlya-menya-ne-muzey-a-mesto-sily-rasskaz-ili-tolstogo-potomka-pisatelya/"
                    className="magazines__link-box"
                    target="_blank"
                  >
                    <svg
                      className="magazines__link-icon"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.04913 12.9509C0.316955 10.2187 0.316955 5.78132 3.04913 3.04914C5.78131 0.316955 10.2187 0.316955 12.9509 3.04914C15.683 5.78132 15.683 10.2187 12.9509 12.9509C10.2187 15.683 5.78131 15.683 3.04913 12.9509Z"
                        strokeWidth={2}
                      />
                    </svg>
                    daily.afisha.ru
                  </a>
                </div>
              </div>
              <div className="hidden md:block w-full px-4 w-100 md:w-1/3 pb-10 magazines__block">
                <div className="magazines__logo">
                  <img src={logo2} alt="logo2" />
                </div>
                <div className="magazines__text">
                  The Village сообщает: лаконичные бутылки с&nbsp;яблочным соком
                  уже продаются в&nbsp;самой усадьбе, в&nbsp;&laquo;Болотов
                  Даче&raquo;, в&nbsp;ресторане &laquo;Марк и&nbsp;Лев&raquo;
                  и&nbsp;в&nbsp;некоторых ресторанах Москвы.
                </div>
                <div className="magazines__link">
                  <img
                    className="magazines__link-icon"
                    src={iconCircle}
                    alt=""
                  />
                  <a
                    href="https://www.the-village.ru/food/produkty-1/soki-yasnaya-polyana"
                    target="_blank"
                  >
                    www.the-village.ru
                  </a>
                </div>
              </div>
              <div className="hidden md:block w-full px-4 w-100 md:w-1/3 pb-10 magazines__block">
                <div className="magazines__logo">
                  <img src={logo3} alt="logo2" />
                </div>
                <div className="magazines__text">
                  Ilya Tolstoy left a&nbsp;journalism career in&nbsp;the big
                  city for his great-great-great-grandfather&rsquo;s famous
                  estate. Now his new apple business is&nbsp;bearing fruit.
                </div>
                <div className="magazines__link">
                  <img
                    className="magazines__link-icon"
                    src={iconCircle}
                    alt=""
                  />
                  <a
                    href="https://www.themoscowtimes.com/2020/11/20/a-tolstoy-descendant-returns-to-his-roots-in-yasnaya-polyana-a72090"
                    target="_blank"
                  >
                    www.themoscowtimes.com
                  </a>
                </div>
              </div>
            </div>

            {/* bottle */}
            <div className="page-bottle-trigger">
              <div id="bottleContainer360" className="bottle360-container">
                <div className="bottle360-wrapper" id="bottle360Wrapper">
                  <img className="bottle-up" src={bottleUp} alt="bottleUp" />
                  <canvas id="bottle360" className="bottle360-canvas" />
                  <img
                    className="bottle-bottom"
                    src={bottleBottom}
                    alt="bottleBottom"
                  />
                </div>
              </div>
              {/* bottle */}

              {/* <img src={dot} alt="" /> */}
              {/* <a className="news__link">подробнее в блоге</a> */}
            </div>

            <div className="flex flex-wrap -mx-4 pt-32 news">
              <div className="w-full px-4 w-100 pb-10 news__block">
                <div className="news-wrap">
                  <div className="news__date">{t("newsDate")}</div>
                </div>
                <div className="news__text">{t("newsText")}</div>

                <div className="news__link">
                  {/* <img
                      className="magazines__link-icon"
                      src={iconCircle}
                      alt=""
                    /> */}
                  <a
                    href="https://pentawards.com"
                    className="news__link-box"
                    target="_blank"
                  >
                    <svg
                      className="news__link-icon"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.04913 12.9509C0.316955 10.2187 0.316955 5.78132 3.04913 3.04914C5.78131 0.316955 10.2187 0.316955 12.9509 3.04914C15.683 5.78132 15.683 10.2187 12.9509 12.9509C10.2187 15.683 5.78131 15.683 3.04913 12.9509Z"
                        strokeWidth={2}
                      />
                    </svg>
                    pentawards.com
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 pt-2 sm:pt-56 insta-container">
              <div className="flex flex-wrap px-4 instagram">
                <div className="flex flex-wrap xl:w-1/3">
                  <div className="w-full pb-2">
                    <div className="instagram__text">{t("followInsta")}</div>
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @yasnaya.ru
                    </a>
                  </div>
                </div>
                <div className="xxl:flex xxl:flex-wrap hidden xxl:w-1/6 xl:hidden"></div>
                <div className="flex flex-wrap -mx-4 xl:w-1/2">
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta1} alt="" />
                    </a>
                  </div>
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta2} alt="" />
                    </a>
                  </div>
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta3} alt="" />
                    </a>
                  </div>
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta4} alt="" />
                    </a>
                  </div>
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta5} alt="" />
                    </a>
                  </div>
                  <div className="px-4 py-4 w-1/3 instagram__image">
                    <a
                      href="https://www.instagram.com/yasnaya.ru"
                      className="instagram__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta6} alt="" />
                    </a>
                  </div>
                </div>
                <div className="xl:flex xl:flex-wrap hidden xl:w-1/6 2xl:hidden"></div>
              </div>
            </div>
          </div>

          {/* bottle 1 */}
          {/* <div className="">
              <div className="row box justify-center">
                <div className="col w-full md:w-2/3">
                  <div
                    id="bottleContainer360"
                    className="bottle360-container w-full"
                  >
                    <div className="bottle360-wrapper" id="bottle360Wrapper">
                      <img
                        className="bottle-up"
                        src={bottleUp}
                        alt="bottleUp"
                      />
                      <canvas id="bottle360" className="bottle360" />
                      <img
                        className="bottle-bottom"
                        src={bottleBottom}
                        alt="bottleBottom"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* bottle 1 */}
        </main>

        <Footer />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
